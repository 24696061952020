import { axios } from '@/utils/request'

export function getPresetConfig(params) {
    return axios({
        url: 'commons/preset_config',
        method: 'get',
        params: params
    })
}

export function getUploadPayload(params) {
    return axios({
        url: 'uploads/payload',
        method: 'get',
        params: params
    })
}

export function getBulletinList() {
    return axios({
        url: 'bulletins',
        method: 'get'
    })
}

export function getLatestVersion() {
    return axios({
        url: 'commons/latest_version',
        method: 'get'
    })
}

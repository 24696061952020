<template>
    <div class="hand-box">
        <div class="checker-brand-box">
            <van-image fit="contain" height="100" :radius="4" :src="church.logo" />
            <div class="brand-title">{{ church.name }}</div>
        </div>

        <div class="checker-buttons" style="bottom: 176px" v-if="loading">
            <van-loading type="spinner" />
        </div>

        <div v-else>
            <div class="checker-buttons" style="bottom: 135px">
                <van-button type="info" plain block class="button" @click="handleDownload('android')" icon="https://jdt-1251146898.cos.ap-shanghai.myqcloud.com/icons/android.png">安卓下载</van-button>
            </div>
            <div class="checker-buttons">
                <van-button type="info" plain block class="button" @click="handleDownload('ios')" icon="https://jdt-1251146898.cos.ap-shanghai.myqcloud.com/icons/ios.png">IOS下载</van-button>
            </div>
        </div>

        <div class="checker-footer">
            {{ church.meta.slogan }}
        </div>
    </div>
</template>

<script>
import { getChurchByDomain } from '@/api/media'
import { getLatestVersion } from '@/api/common'
import { isWeixinBrowser } from '@/utils/util'

export default {
    name: 'HandBox',
    data () {
        return {
            church: {
                name: '',
                logo: '',
                meta: {
                    slogan: ''
                }
            },
            app: {},
            loading: true
        }
    },
    methods: {
        handleDownload(platform) {
            const info = this.app[platform]
            if (!info) {
                this.$notify({ type: 'success', message: '正在上架中' })
                return
            }
            if (isWeixinBrowser()) {
                this.$notify({ type: 'warning', message: '请点击右上角菜单, 在自带浏览器中打开' })
                return
            }

            window.open(info.download_url, '_blank');
        }
    },
    async created() {
        //获取基础信息
        const churchRes = await getChurchByDomain()
        this.church = churchRes.data

        //获取下载信息
        const appRes = await getLatestVersion()
        this.app = appRes.data

        this.loading = false
    }
}
</script>

<style lang="less" scoped>
.hand-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
}
.checker-brand-box {
    position: absolute;
    top: 114px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    text-align: center;
    font-weight: lighter;

    .brand-title {
        margin: 8px 0px;
        color: #323233;
    }
}
.checker-buttons {
    position: absolute;
    bottom: 76px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .button {
        width: 260px;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        position: relative;
        margin-bottom: 16px;
        //height: 50px;
        font-size: 16px;
    }
}
.checker-footer {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #7e838c;
}
</style>
